<template>
  <div class="main">
    <div class="dealer-box">
      <div class="border-box">
        <div class="activities-slogan.png">
          <img
            src="@/assets/images/dealer/activities-slogan.png"
            alt=""
          >
        </div>
        <div class="slogan-text">
          注册大大汽配，不做咸鱼
        </div>
      </div>
      <div class="text-company">
        <span>
          <img
            :src="(garageIntroduction.url) ? avatarUrl(garageIntroduction.url):require('@/assets/images/grab-red-packet/default.png')"
            alt=""
          ></span>
        <div>
          <h1>{{garageIntroduction.name}}</h1>
          邀请你成为大大汽配平台汽配商
        </div>
      </div>
      <div class="center">
        <div class="dealer-top">
          <img
            src="/images/dealer/top.png"
            alt=""
          >
        </div>
        <div class="table-border">
          <div class="box">
            <h1>申请参与</h1>
            <div class="input">
              <div class="input-box">
                <cube-input
                  placeholder="请输入门店名称"
                  class="input"
                  v-model="dealerName"
                  @blur="blurName"
                ></cube-input>
                <i>门店名称</i>
              </div>
              <div
                class="input-box"
                @click="showCascadePicker"
              >
                <div
                  class="input-select"
                  :style="selectedDistrict ? 'color:#363636' : 'color:#ccc'"
                >{{selectedDistrict || '请选择所在地区'}}</div>
                <i>选择地区</i>
              </div>
              <div class="input-box">
                <cube-input
                  placeholder="请输入详细地址"
                  v-model="detailAddress"
                  class="input"
                  @blur="blurName"
                ></cube-input>
                <i>详细地址</i>
              </div>
              <div class="input-box">
                <cube-input
                  placeholder="请输入联系人"
                  v-model="contactName"
                  class="input"
                  @blur="blurName"
                ></cube-input>
                <i>联系人</i>
              </div>
              <div class="input-box">
                <cube-input
                  placeholder="请输入手机号"
                  v-model="contactPhone"
                  class="input"
                  @blur="blurName"
                ></cube-input>
                <i>手机号</i>
              </div>
              <!------图形刷新----->
              <div
                v-if="needImageCodeFlag"
                class="graphical"
              >
                <cube-input
                  v-model="imgVerifyCodeValue"
                  placeholder="图形码"
                  class="input-border"
                  @blur="blurName"
                ></cube-input>
                <div
                  class="graphical-img"
                  @click="getDealerImageCode"
                ><img
                    v-if="imageCodeSrc"
                    :src="base64Prefix+imageCodeSrc"
                  >
                  <span v-else>图形验证码</span>
                </div>
                <div
                  @click="getDealerImageCode"
                  class="refresh"
                >刷新</div>
              </div>
              <!------立即参与----->
              <div class="graphical">
                <!-- <cube-input placeholder="验证码"></cube-input> -->
                <cube-input
                  class="input-border"
                  placeholder="请输入验证码"
                  v-model="verifyCode"
                  @blur="blurName"
                ></cube-input>
                <div class="graphical-code">
                  <span
                    v-if="sendCaptchaEnabled"
                    class="get-code"
                    @click="sendCaptcha()"
                    @blur="blurName"
                  >{{getVcode}}</span>
                  <span
                    v-else
                    class="after-get-code"
                  >{{counterTimer}}秒后重新获取</span></div>
              </div>
              <!------立即参与----->
              <div
                class="receive"
                @click="loggingOn"
              >
                立即参与
              </div>
            </div>
          </div>
        </div>
        <div class="dealer-bottom">
          <img
            src="@/assets/images/dealer/bottom.png"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeInformation, dealerCheck, dealerCode, getDealerImageCode } from '@/api/dealer'
import { regionData } from 'element-china-area-data'
import { isWeiXin, isIOS } from '@/utils/wx'
import { setSessionStorage } from '@/utils/store'
export default {
  name: 'dealer-activities',
  data () {
    return {
      contactPhone: '',
      contactName: '',
      detailAddress: '',
      dealerName: '',
      verifyCode: '',
      sendCaptchaEnabled: true,
      needImageCodeFlag: false,
      imgVerifyCodeValue: '',
      sending: false,
      getVcode: '获取验证码',
      counterTimer: 60,
      userId: '',
      base64Prefix: 'data:image/png;base64,',
      imageCodeSrc: '',
      id: '',
      bodyHeight: {
        height: 'auto'
      },
      garageIntroduction: {
        name: '',
        url: ''
      },
      phoneHeight: 0,
      cascadePicker: {},
      selectedDistrict: '',
      province: '',
      city: '',
      district: ''
    }
  },
  beforeCreate () {
    setSessionStorage('token', this.$route.query.token)
    document.getElementsByTagName('body')[0].setAttribute('style', 'background:#5E196A')
  },
  mounted () {
    this.cascadePicker = this.$createCascadePicker({
      title: '请选择地区',
      data: regionData,
      alias: {
        text: 'label'
      },
      onSelect: (selectedVal, selectedIndex, selectedText) => {
        this.districtPickerVisible = false
        this.selectedDistrict = selectedText.join('/')
        this.province = selectedText[0] || ''
        this.city = selectedText[1] || ''
        this.district = selectedText[2] || ''
        console.log(selectedText)
      },
      onCancel: () => {
        this.districtPickerVisible = false
      }
    })

    let picker = document.getElementsByClassName('cube-picker-choose')
    picker[0].classList.add('area-picker')

    this.userId = this.$route.query.userId
    console.log(this.$route.query.token)
    this.repairDepot(this.userId)
    // 是否归为
    let isReset = true
    console.log('isIOS', isIOS())
    console.log('isWeiXin', isWeiXin())
    if (isIOS() && isWeiXin()) {
      document.body.addEventListener('focusin', () => {
        // 软键盘弹出的事件处理
        isReset = false
        console.log('软键盘弹出')
      })
      document.body.addEventListener('focusout', () => {
        // 软键盘收起的事件处理
        isReset = true
        console.log('软键盘收起')
        setTimeout(() => {
          // 当焦点在弹出层的输入框之间切换时先不归位
          if (isReset) {
            // 失焦后强制让页面归位
            window.scroll(0, 0)
          }
        }, 300)
      })
    }
  },
  methods: {
    blurName () {
      setTimeout(() => {
        const newHeight = document.body.clientHeight || document.documentElement.clientHeight
        if (this.phoneHeight - newHeight > 200) return (document.body.scrollTop = document.body.scrollHeight)
      }, 300)
    },
    showCascadePicker () {
      this.cascadePicker.show()
    },
    selectHandle (selectedVal, selectedIndex, selectedText) {
      this.$createDialog({
        type: 'warn',
        content: `Selected Item: <br/> - value: ${selectedVal.join(', ')} <br/> - index: ${selectedIndex.join(', ')} <br/> - text: ${selectedText.join(' ')}`,
        icon: 'cubeic-alert'
      }).show()
    },
    cancelHandle () {
      this.$createToast({
        type: 'correct',
        txt: 'Picker canceled',
        time: 1000
      }).show()
    },
    avatarUrl (avatar) {
      let path = avatar || ''
      return process.env.VUE_APP_IMAGE_SERVER_URL + path
    },
    repairDepot (id) {
      storeInformation(id).then((res) => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }
        this.garageIntroduction.name = res.data.data.name
        this.garageIntroduction.url = res.data.data.url
      })
    },
    sendCaptcha () {
      if (!this.dealerName.trim()) {
        const toast = this.$createToast({
          txt: `门店名称不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!/^[\u4e00-\u9fa5a-zA-Z0-9]{0,50}$/.test(this.dealerName.trim())) {
        const toast = this.$createToast({
          txt: `门店名称最多填写五十个字`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.province || !this.city || !this.district) {
        const toast = this.$createToast({
          txt: `请填写省市区`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.detailAddress.trim()) {
        const toast = this.$createToast({
          txt: `填写详细地址`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!/^[\u4e00-\u9fa5a-zA-Z0-9]{0,100}$/.test(this.detailAddress.trim())) {
        const toast = this.$createToast({
          txt: `详细地址最多填写一百个字`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.contactName.trim()) {
        const toast = this.$createToast({
          txt: `联系人不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!/^[\u4e00-\u9fa5a-zA-Z0-9]{0,15}$/.test(this.contactName.trim())) {
        const toast = this.$createToast({
          txt: `联系人最多填写十五个字`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.contactPhone.trim()) {
        const toast = this.$createToast({
          txt: `手机号码不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!/^1\d{10}$/.test(this.contactPhone.trim())) {
        const toast = this.$createToast({
          txt: `手机号错误，请重新输入！`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.sendCaptchaEnabled) {
        return
      }
      if (this.sending) {
        return
      }
      this.sending = true
      let params = {
        phone: this.contactPhone
      }
      if (this.needImageCodeFlag) {
        if (!this.imgVerifyCodeValue) {
          return this.$createToast({
            txt: `图片验证码不能为空`,
            type: 'error',
            time: 2000
          }).show()
        } else {
          params.imgVerifyCodeValue = this.imgVerifyCodeValue
        }
      }
      dealerCode(JSON.stringify(params)).then((res) => {
        this.sending = false
        const { result, desc, data } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.needImageCodeFlag = !!data.verifyCode
        if (!this.needImageCodeFlag) {
          this.sendCaptchaEnabled = false
          this.timer = setInterval(() => {
            if (this.counterTimer <= 0) {
              this.counterTimer = 60
              this.sendCaptchaEnabled = true
              this.getVcode = '重新获取'
              clearInterval(this.timer)
            }
            this.counterTimer--
          }, 1000)
        } else {
          this.getDealerImageCode()
        }
      })
    },
    loggingOn () {
      if (!this.dealerName.trim()) {
        const toast = this.$createToast({
          txt: `门店名称不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!/^[\u4e00-\u9fa5a-zA-Z0-9]{0,50}$/.test(this.dealerName.trim())) {
        const toast = this.$createToast({
          txt: `门店名称最多填写五十个字`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.province || !this.city || !this.district) {
        const toast = this.$createToast({
          txt: `请填写省市区`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.detailAddress.trim()) {
        const toast = this.$createToast({
          txt: `详细地址`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!/^[\u4e00-\u9fa5a-zA-Z0-9]{0,100}$/.test(this.detailAddress.trim())) {
        const toast = this.$createToast({
          txt: `详细地址最多填写一百个字`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.contactName.trim()) {
        const toast = this.$createToast({
          txt: `联系人不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!/^[\u4e00-\u9fa5a-zA-Z0-9]{0,15}$/.test(this.contactName.trim())) {
        const toast = this.$createToast({
          txt: `联系人最多填写十五个字`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.contactPhone.trim()) {
        const toast = this.$createToast({
          txt: `手机号码不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!/^1\d{10}$/.test(this.contactPhone.trim())) {
        const toast = this.$createToast({
          txt: `手机号错误，请重新输入！`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.verifyCode.trim()) {
        const toast = this.$createToast({
          txt: `验证码不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (this.verifyCode.trim().length < 6) {
        const toast = this.$createToast({
          txt: `验证码不正确`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (this.sending) {
        return
      }
      let params = {
        contactPhone: this.contactPhone,
        verifyCode: this.verifyCode,
        userId: this.userId,
        dealerName: this.dealerName,
        detailAddress: this.detailAddress,
        contactName: this.contactName,
        province: this.province,
        city: this.city,
        district: this.district
      }
      this.sending = true
      dealerCheck(JSON.stringify(params)).then((res) => {
        this.sending = false
        let { result, desc } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.$router.replace({ path: '/dealer-successfully' })
      })
    },
    // 获取图形验证码
    getDealerImageCode () {
      const phone = this.contactPhone.trim()
      getDealerImageCode(phone).then((res) => {
        const { result, desc } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.imageCodeSrc = desc
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index.scss";
// html {
//   font-family: Arial, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
//     Microsoft YaHei, SimSun, SimHei, sans-serif;
//   background: #9e0 !important;
// }

img {
  width: 100%;
  height: auto;
  width: auto\9; /* ie8 */
  display: block;
  -ms-interpolation-mode: bicubic;
}
.border-box {
  padding: px2rem(50px) px2rem(80px) 0;
}
.main {
  background: linear-gradient(
    180deg,
    rgba(39, 26, 157, 1) 0%,
    rgba(94, 25, 106, 1) 100%
  );
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: px2rem(25px);
}
.dealer-box {
  background: url("~images/dealer/activities.png") no-repeat top center;
  background-size: 99.99% 99.99%;
  z-index: -1;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
}
.slogan-text {
  font-size: px2rem(36px);
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  padding-top: px2rem(14px);
}
.text-company {
  width: px2rem(600px);
  margin-top: px2rem(314px);
  // margin-left: px2rem(55px);
  margin: px2rem(314px) auto px2rem(25px) auto !important;
  height: px2rem(112px);
  display: flex;
  padding: px2rem(19px) px2rem(20px);
  background: linear-gradient(
    45deg,
    rgba(229, 66, 94, 1) 0%,
    rgba(189, 104, 227, 1) 100%
  );
  border-radius: 11px;
  // margin-bottom: px2rem(25px);
  span {
    width: px2rem(112px);
    height: px2rem(112px);
    border-radius: 50%;
    img {
      width: px2rem(112px);
      height: px2rem(112px);
      border-radius: 50%;
    }
  }
  div {
    font-size: px2rem(28px);
    padding-left: px2rem(20px);
    line-height: px2rem(56px);
    h1 {
      font-size: px2rem(36px);
    }
  }
}
.center {
  width: px2rem(640px);
  margin: 0 auto;
}
.dealer-top {
  width: px2rem(640px);
  // margin-left: px2rem(55px);
  // height: px2rem(59px);
  img {
    margin-bottom: px2rem(-2px);
    height: px2rem(40px);
  }
}
.dealer-bottom {
  width: px2rem(604px);
  margin-left: px2rem(19px);
  padding-bottom: px2rem(5px);
  margin-top: px2rem(-2px);
  margin: px2rem(-2px) auto 0 auto;
}
.table-border {
  background: url("~images/dealer/line.png") repeat-y;
  background-size: 100% 100%;
  width: px2rem(604px);
  color: #fff;
  padding-bottom: px2rem(10px);
  margin-left: px2rem(19px);
  margin: 0 auto;
  padding: px2rem(-2px) 0;
  h1 {
    font-size: px2rem(48px);
    font-weight: 600;
    color: rgba(255, 80, 81, 1);
    line-height: px2rem(67px);
    padding-bottom: px2rem(30px);
    text-align: center;
    width: px2rem(604px);
  }
  .input-box {
    position: relative;
    width: px2rem(420px);
    margin: 0 0 px2rem(30px) px2rem(30px);
    height: auto;
    color: #363636;
    i {
      color: #363636;
      border-right: 1px solid #cdcdcd;
      height: px2rem(25px);
      position: absolute;
      top: px2rem(33px);
      left: px2rem(20px);
      position: absolute;
      font-size: px2rem(28px);
      font-style: normal;
      width: px2rem(120px);
    }
    .input {
      width: px2rem(380px) !important;
      border: px2rem(2px) solid rgba(255, 80, 81, 1);
      height: px2rem(84px);
      border-radius: 6px;
      font-size: px2rem(28px);
      padding-left: px2rem(160px);
      color: #363636;
      // line-height: px2rem(60px);
    }
    .input-select {
      width: px2rem(380px) !important;
      border: px2rem(2px) solid rgba(255, 80, 81, 1);
      height: px2rem(84px);
      border-radius: 6px;
      font-size: px2rem(28px);
      padding-left: px2rem(160px);
      line-height: px2rem(82px);
      color: #999;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        right: px2rem(20px);
        top:  px2rem(37px);
        border-top: px2rem(10px) solid #999;
        border-left: px2rem(12px)  solid transparent;
        border-right: px2rem(12px)  solid transparent;
      }
    }
  }
  .box {
    width: px2rem(560px);
  }
}
.graphical {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 px2rem(30px) px2rem(30px);
  font-size: px2rem(28px);
  width: px2rem(540px);
  .input-border {
    width: px2rem(490px);
    height: px2rem(84px);
    border-radius: 6px;
    border: px2rem(2px) solid rgba(255, 80, 81, 1);
    font-size: px2rem(28px);
    padding: 0 px2rem(10px);
  }
  .graphical-img img {
    color: #363636;
    background: #f0f0f0;
    line-height: px2rem(84px);
    margin-left: px2rem(30px);
    width: px2rem(160px);
    text-align: center;
  }
  .refresh {
    font-size: px2rem(28px);
    color: #363636;
    width: px2rem(90px);
    display: inline-block;
    line-height: px2rem(84px);
    text-align: center;
  }
}
.receive {
  font-size: px2rem(32px);
  width: px2rem(540px);
  height: px2rem(90px);
  background: linear-gradient(
    90deg,
    rgba(255, 149, 90, 1) 0%,
    rgba(255, 79, 81, 1) 100%
  );
  border-radius: 6px;
  text-align: center;
  line-height: px2rem(90px);
  color: #fff;
  margin: px2rem(20px) px2rem(32px) 0;
}
.graphical-code {
  width: px2rem(260px);
  height: px2rem(84px);
  border-radius: 6px;
  border: px2rem(2px) solid rgba(255, 82, 82, 1);
  text-align: center;
  margin-left: px2rem(30px);
  color: #ff5051;
  line-height: px2rem(84px);
  text-align: center;
}
.get-code {
  font-size: px2rem(28px);
  font-weight: 400;
   color: #ff5051;
  line-height: px2rem(84px);
  height: px2rem(84px);
  display: inline-block;
  z-index: 999999999;
  text-align: center;
}
.after-get-code {
  font-size: px2rem(28px);
  font-weight: 400;
  color: #ff5051;
  line-height: px2rem(40px);
  height: px2rem(84px);
  display: inline-block;
  z-index: 999999999999999999999999;
  text-align: center;
}
</style>
<style>
.cube-input::after {
  border: none !important;
}
.cube-input-field {
  color: #363636;
}
</style>

